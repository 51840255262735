import React from 'react'

import ServiceListItem from './ServicesListItem'

export default ( props ) => {

	const { show, services } = props.input

	return (
		<>
			{ show &&
				<section className="services-list wrapper">
					{ services.map(( { service }, index ) => (
						<ServiceListItem service={ service } index={ index } key={ index } />
					) ) }
				</section>
			}
		</>
	)
}