import React from 'react'
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown'

export default function TimelineItem( props ) {

	const { data } = props.data

	return (
		<div className="timeline-item">
			<div className="timeline-item__image-container">
				<Img className="timeline-item__image" fluid={ data.image.imageFile.childImageSharp.fluid } />
			</div>
			<div className="timeline-item__title">
				{ data.title }
			</div>
			<ReactMarkdown
				className="timeline-item__description"
				escapeHtml={ false }
				source={ data.content }
			/>
		</div>
	)
}