import React from 'react'
import { Link } from 'gatsby'

import Arrow from '../../../images/svgs/arrow.svg'

import { addTrailingSlash } from '../../../helpers/urlHelpers'

export default function SectionLink ( props ) {

	const { linkTitle, linkUrl } = props.input

	return (
		<section className="section-link">
			<Link className="section-link__link" to={ addTrailingSlash( linkUrl ) }>
				<h3 className="section-link__title" >{ linkTitle }</h3>
				<Arrow className="section-link__arrow" />
			</Link>
		</section>
	)
}
