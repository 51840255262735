import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import Arrow from '../../../images/svgs/arrow.svg'
import ComponentAnimation from '../../ComponentAnimation/ComponentAnimation'

export default ( props ) => {

	const { service, index } = props

	return (
		<ComponentAnimation>
			<div className={`services-list__container ${ index % 2 !== 0 ? 'reverse' : '' }`} key={ service.id }>
				<div className="services-list__image-container">
					<Img className="services-list__image" fluid={ service.image.imageFile.childImageSharp.fluid } />
				</div>
				<div className="services-list__info-container">
					<h3 className="services-list__title">{ service.title }</h3>
					<p className="services-list__intro">{ service.intro }</p>
					<Link to={`/services/${ service.slug }/`} className="services-list__link">
						Click here to read more about this programme
						<Arrow className="services-list__arrow" />
					</Link>
				</div>
			</div>
		</ComponentAnimation>
	)
}