import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'

export default function TimelineItem( props ) {

	const { data, currentIndex, handleButtonClick, totalEvents } = props
	const [ width, setWidth ] = useState( 100 )
	const [ scroll, setScroll ] = useState( 0 )
	const scrollAmount = 20

	// Finds how much to translate the timeline ( may be worth putting in useEffect?)
	const findTranslation = () => {
		const sizeOfDiv = width / totalEvents
		return ( ( sizeOfDiv ) / width ) * 100 * ( currentIndex + 0.5 )
	}

	// handles click on the date
	const handleDateClick = ( event ) => {
		const index = parseInt( event.target.getAttribute( 'data-index' ) );
		handleButtonClick( index - currentIndex )
	}

	// Handles horizontal scroll on timeline
	const useHorizontalScroll = () => {
		const ref = useRef();

		useEffect( () => {
			const el = ref.current;
			if ( el ) {
				const onWheel = e => {
					if (e.deltaY === 0) return;
					e.preventDefault();
					setScroll( state => state + e.deltaY )
				};

				el.addEventListener("wheel", onWheel);
				return () => el.removeEventListener("wheel", onWheel);
			}
		}, []);

		return ref;
	}

	const scrollRef = useHorizontalScroll();

	useEffect(() => {
		// setting width for translation of timeline
		if ( scrollRef.current ) {
			setWidth( scrollRef.current.offsetWidth )
		}
	}, [ scrollRef ])

	// Use effect for the horizontal scroll, set scroll Amount for different sensitivity 
	useEffect(() => {
		// if the scroll has passed scrollAmount add change the current index
		if ( Math.abs( scroll ) - scrollAmount > 0 ) {
			// if no current index cant go to index -1, same as if the current index + 1 is greater than total Events
			if ( currentIndex && scroll < 0 ) {
				handleButtonClick( -1 )
			} else if ( currentIndex + 1 !== totalEvents && scroll > 0 ) {
				handleButtonClick( 1 )
			}
			setScroll( 0 )
		}
	}, [ scroll, handleButtonClick, totalEvents, currentIndex ])

	return (
		<div className="timeline-bar" ref={ scrollRef } >
			<ul className="timeline-bar__list" style={{ transform: `translateX(-${ findTranslation() }%)`}} id="timeline">
				{ data.map( ( time, index ) => (
					<li className={`timeline-bar__list-item ${ index === currentIndex ? 'active' : ''}`} key={ time.id }>
						<button className="timeline-bar__date"
							data-index={ index }
							onClick={ e => handleDateClick( e ) } >
								{ moment( time.date ).format( "MMM 'YY" ) }
						</button>
					</li>
				)) }
			</ul>
		</div>
	)
}