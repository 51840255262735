import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import Img from 'gatsby-image'

export default function AboutTheHexagon ( props ) {

	const { hexagonTitle, hexagonDescription, hexagonImages } = props.input

	const [ imageIndex, setImageIndex ] = useState( 0 )
	const totalImages = hexagonImages.length

	useEffect(() => {
		if ( totalImages === 1 ) return

		// Add 5 second timer to the slider
		const timer = setInterval( () => setImageIndex( i => (i + 1) % totalImages ), 5000)
		return () => clearInterval( timer );

	}, [ totalImages, setImageIndex ])

	return (
		<section className="about-the-hexagon">
			<div className="about-the-hexagon__wrapper wrapper">
				<div className="about-the-hexagon__text-container">
					{ hexagonTitle && <h3 className="about-the-hexagon__title">{ hexagonTitle }</h3> }
					{ hexagonDescription &&
						<ReactMarkdown
							className="about-the-hexagon__description"
							escapeHtml={ false }
							source={ hexagonDescription }
						/>
					}
				</div>

				{ hexagonImages &&
					<div className="about-the-hexagon__image-container">
						{ hexagonImages.map( ( image, index ) => (
							<div className={`about-the-hexagon__image-slide ${ index === imageIndex ? "active" : "" }`} key={ index }>
								<Img className="about-the-hexagon__image" fluid={ image.imageFile.childImageSharp.fluid } />
							</div>
						)) }
					</div>
				}
			</div>
		</section>
	)
}
