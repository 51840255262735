import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { addTrailingSlash } from '../../../helpers/urlHelpers'

export default function LinkStatement( props ) {

	const { linkStatementLinkText, linkStatementTitle, linkStatementUrl} = props.input

	return (
		<div className="link-statement wrapper">
			<h2 className="link-statement__title">{ linkStatementTitle }</h2>
			<Link to={ addTrailingSlash( linkStatementUrl ) } className="link-statement__link" >
				{ linkStatementLinkText }
			</Link>
		</div>
	)
}

LinkStatement.propTypes = {
	input: PropTypes.object.isRequired
};