import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

export default function AboutInfoLarge ( props ) {

	const { aboutInfoLargeTitle, aboutInfoLargeDescription, aboutInfoLargeImage } = props.input

	return (
		<section className="about-info-large wrapper">
			<div className="about-info-large__wrapper">
				<div className="about-info-large__info-container">
					<h2 className="about-info-large__title">{ aboutInfoLargeTitle }</h2>
					<ReactMarkdown
						className="about-info-large__description"
						escapeHtml={ false }
						source={ aboutInfoLargeDescription }
					/>
				</div>
				<div className="about-info-large__image-container">
					<Img className="about-info-large__image" fluid={{ ...aboutInfoLargeImage.imageFile.childImageSharp.fluid, aspectRatio: 4/3 }} />
				</div>
			</div>
		</section>
	)
}
