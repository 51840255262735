import React from 'react'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

export default function ProfileList ( props ) {

	const { profileList, profileListTitle } = props.input

	return (
		<section className="profile-list">
			<div className="profile-list__wrapper wrapper">
				{ profileListTitle && <h2 className="profile-list__main-title">{ profileListTitle }</h2> }
				<div className="profile-list__list">
					{ profileList.map( card => (
						<div className="profile-list__card-container" key={ card.id }>
							<div className="profile-list__image-container">
								<Img fluid={{ ...card.profileImage.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="profile-list__image" />
							</div>
							<div className="profile-list__title">{ card.profileName }</div>
							<ReactMarkdown
								className="profile-list__description"
								escapeHtml={ false }
								source={ card.profileSummary }
								/>
						</div>
					))}
				</div>
				
			</div>
		</section>
	)
}
