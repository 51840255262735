import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import AlitusLogo from '../../../images/svgs/alitus-logo.svg'
import BlossomLogo from '../../../images/svgs/blossom-logo.svg'
import DreamonLogo from '../../../images/svgs/dream-on-logo.svg'

export default function WiderFamilyItem ( props ) {

	const { data, index } = props
	const [ active, setActive ] = useState()

	const renderLogo = logo => {

		switch( logo ) {
			case 'alitus':
				return <AlitusLogo className="wider-family__logo" />
			case 'blossom':
				return <BlossomLogo className="wider-family__logo wider-family__logo--blossom" />
			case 'dreamon':
				return <DreamonLogo className="wider-family__logo" />
			default:
				return null
		}
	}

	return (
		<div className={`wider-family__item ${ index % 2 !== 0 ? 'wider-family__item--alt' : '' }`}>
			<div className="wider-family__text-container">
				<h3 className="wider-family__item-heading">{ data.name }</h3>
				<span className="wider-family__website">{ data.url }</span>
				<p className="wider-family__summary">{ data.summary }</p>

				{ active &&
					<ReactMarkdown
						className="wider-family__main"
						escapeHtml={ false }
						source={ data.main } />
				}

				<div className="wider-family__links-container">
					{ !active && <button className="wider-family__link" onClick={ () => setActive( true ) }>Read more here</button> }
					{ data.logo !== 'blossom' && <a className="wider-family__link" href={ `https://${ data.url }` } target="_blank" rel="noreferrer">Visit website here</a> }
				</div>
			</div>
			<div className="wider-family__logo-container">
				{ renderLogo( data.logo ) }
			</div>
		</div>
	)
}