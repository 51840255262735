import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'

export default function AboutInfoSmall ( props ) {

	const { aboutInfoSmallTitle, aboutInfoSmallDescription, aboutInfoSmallReverse, aboutInfoSmallImage } = props.input

	return (
		<section className={`about-info-small ${ aboutInfoSmallReverse ? 'reverse' : '' } wrapper`}>
			<div className={ `about-info-small__wrapper ${ aboutInfoSmallReverse ? 'reverse' : '' }` }>
				<div className={`about-info-small__image-container ${ aboutInfoSmallReverse ? 'reverse' : '' }` }>
					<Img className="about-info-small__image" fluid={{ ...aboutInfoSmallImage.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} />
				</div>
				<div className="about-info-small__info-container">
					<h2 className="about-info-small__title">{ aboutInfoSmallTitle }</h2>
					<ReactMarkdown
						className="about-info-small__description"
						escapeHtml={ false }
						source={ aboutInfoSmallDescription }
					/>
				</div>
			</div>
		</section>
	)
}
