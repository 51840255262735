import React from 'react'

export default function Statement ( props ) {

	const { statementTitle, statementSubTitle } = props.input

	return (
		<div className="statement wrapper">
			<h2 className="statement__title">{ statementTitle }</h2>
			{ statementSubTitle && <h3 className="statement__sub-title">{ statementSubTitle }</h3> }
		</div>
	)
}
