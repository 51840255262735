import React from 'react'

import Anchor from '../../Anchor/Anchor'
import WiderFamilyItem from './WiderFamilyItem'

export default function WiderFamily ( props ) {

	const data = props.input

	return (
		<section className="wider-family">
			<Anchor anchorId="wider-family" />
			<div className="wider-family__wrapper wrapper">
				<h2 className="wider-family__heading" >{ data.heading }</h2>

				<div className="wider-family__list">
					{ data.families.map( ( family, index ) => (
						<WiderFamilyItem data={ family } index={ index } key={ family.id } />
					) ) }
				</div>
			</div>
		</section>
	)
}