import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import TimelineItem from './TimelineItem'
import TimelineBar from './TimelineBar'

import {
	HiOutlineArrowLeft,
	HiOutlineArrowRight } from 'react-icons/hi'

export default function Timeline( props ) {

	const { show } = props.input

	const queryData = useStaticQuery(graphql`
		query CategoryNavQuery12 {
			strapi {
				timelines(sort: "date:ASC") {
					id
					title
					content
					date
					image {
						url
						id
						imageFile {
							childImageSharp {
								fluid(maxWidth: 500, maxHeight: 500) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
	`)

	const data = queryData.strapi.timelines
	const totalEvents = data.length;

	const [ state, setState ] = useState({ data: data[0], currentIndex: 0 })

	const handleButtonClick = ( increment ) => {
		setState({
			data: data[ state.currentIndex + increment ],
			currentIndex: state.currentIndex + increment
		});
	}

	if ( !data || !show ) {
		return null
	}

	return (
		<section className="timeline">
			<div className="timeline__wrapper wrapper">
				<h2 className="timeline__heading">Read our major milestones below...</h2>
				<TimelineItem data={ state }/>

				<TimelineBar
					currentIndex={ state.currentIndex }
					data={ data } 
					handleButtonClick={ handleButtonClick }
					totalEvents={ totalEvents } />

				<HiOutlineArrowLeft
					className={`timeline__button timeline__button--prev ${ !state.currentIndex ? 'disabled' : '' }`}
					onClick={ () => handleButtonClick( -1 ) }
					/>
	
				<HiOutlineArrowRight
					className={`timeline__button timeline__button--next ${ state.currentIndex + 1 === totalEvents ? 'disabled' : '' }`}
					onClick={ () => handleButtonClick( 1 ) }
					/>
			</div>
		</section>
	)
}