import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import ReactMarkdown from 'react-markdown'

import { addTrailingSlash } from '../../../helpers/urlHelpers'

export default function imageCardList ( props ) {

	const { imageCard } = props.input

	return (
		<section className="image-card-list">
			<div className="image-card-list__wrapper wrapper">
				{ imageCard.map( card => (
					<div className="image-card-list__card-container" key={ card.id }>
						<div className="image-card-list__image-container">
							<Img fluid={{ ...card.imageCardImage.imageFile.childImageSharp.fluid, aspectRatio: 16/9 }} className="image-card-list__image" />
						</div>
						<div className="image-card-list__title">{ card.imageCardTitle }</div>
						<ReactMarkdown
							className="image-card-list__description"
							escapeHtml={ false }
							source={ card.imageCardDescription }
							/>
						{ card.imageCardLink && card.imageCardLinkText &&
							<Link to={ addTrailingSlash( card.imageCardLink ) } className="image-card-list__link">{ card.imageCardLinkText }</Link>
						}
					</div>
				))}
			</div>
		</section>
	)
}
