import React from 'react'

import ArticleImage from './article/ArticleImage'
import ArticleQuote from './article/ArticleQuote'
import ArticeTextBlock from './article/ArticleTextBlock'
import AboutTheHexagon from './contact/AboutTheHexagon'
import CTABanner from './common/CTABanner'
import AboutInfoSmall from './about/AboutInfoSmall'
import AboutInfoLarge from './about/AboutInfoLarge'
import Timeline from './about/Timeline'
import WiderFamily from './about/WiderFamily'
import TeamMembersList from './about/TeamMembersList'
import Statement from './common/Statement'
import SectionLink from './common/SectionLink'
import LinkStatement from './common/LinkStatement'
import Video from './common/Video'
import ServicesList from './services/ServicesList'
import ServicesStandardTextBlock from './services/ServicesStandardTextBlock'
import ImageCardList from './giving/ImageCardList'
import HorizontalImageCard from './giving/HorizontalImageCard'
import ProfileList from './profile/ProfileList'
import Homepage from './homepage/Homepage'

import ComponentAnimation from '../ComponentAnimation/ComponentAnimation'

export default function DynamicZone( { components } ) {

	const findComponent = ( comp, index ) => {
		switch ( comp.__typename ) {
			case 'StrapiQuery_ComponentArticleImage':
				return <ArticleImage key={ index } input={ comp } />
			case 'StrapiQuery_ComponentArticleQuote':
				return <ArticleQuote key={ index } input={ comp } />
			case 'StrapiQuery_ComponentArticleTextBlock':
				return <ArticeTextBlock key={ index } input={ comp } />
			case 'StrapiQuery_ComponentServicesServiceList':
				return <ServicesList key={ index } input={ comp } />
			case 'StrapiQuery_ComponentServicesStandardTextBlock':
				return <ServicesStandardTextBlock key={ index } input={ comp } />
			case 'StrapiQuery_ComponentCommonCallToActionBanner':
				return <CTABanner key={ index } input={ comp } />
			case 'StrapiQuery_ComponentContactAboutTheHexagon':
				return <AboutTheHexagon key={ index } input={ comp } />
			case 'StrapiQuery_ComponentAboutAboutInfoSmall':
				return <AboutInfoSmall key={ index } input={ comp } />
			case 'StrapiQuery_ComponentAboutAboutInfoLarge':
				return <AboutInfoLarge key={ index } input={ comp } />
			case 'StrapiQuery_ComponentAboutTimeline':
				return <Timeline key={ index } input={ comp } />
			case 'StrapiQuery_ComponentAboutWiderFamily':
				return <WiderFamily key={ index } input={ comp } />
			case 'StrapiQuery_ComponentAboutTeamMembersList':
				return <TeamMembersList key={ index } input={ comp } />
			case 'StrapiQuery_ComponentCommonSectionLink':
				return <SectionLink key={ index } input={ comp } />
			case 'StrapiQuery_ComponentCommonStatement':
				return <Statement key={ index } input={ comp } />
			case 'StrapiQuery_ComponentCommonLinkStatement':
				return <LinkStatement key={ index } input={ comp } />
			case 'StrapiQuery_ComponentGivingImageCardList':
				return <ImageCardList key={ index } input={ comp } />
			case 'StrapiQuery_ComponentGivingHorizontalImageCard':
				return <HorizontalImageCard key={ index } input={ comp } />
			case 'StrapiQuery_ComponentProfileProfileList':
				return <ProfileList key={ index } input={ comp } />
			case 'StrapiQuery_ComponentHomepageHomepage':
				return <Homepage key={ index } input={ comp } />
			case 'StrapiQuery_ComponentArticleVideo':
				return <Video key={ index } input={ comp } cssClass="video--article" />
			case 'StrapiQuery_ComponentCommonVideo':
				return <Video key={ index } input={ comp } />
			default:
				return null
		}
	}

	return (
		<>
			{ components.map( ( comp, index ) => (
				<ComponentAnimation key={ index }>
					{ findComponent( comp, index ) }
				</ComponentAnimation>
			) ) }
		</>
	)
}