import React from 'react'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

export default function HorizontalImageCard ( props ) {

	const { horizontalImageCardImage, horizontalImageCardTitle, horizontalImageCardDescription } = props.input

	return (
		<section className="horizontal-image-card">
			<div className="horizontal-image-card__wrapper wrapper">
				<div className="horizontal-image-card__card-container">
					<div className="horizontal-image-card__title">{ horizontalImageCardTitle }</div>
					<ReactMarkdown
						className="horizontal-image-card__description"
						escapeHtml={ false }
						source={ horizontalImageCardDescription }
						/>
				</div>
				<div className="horizontal-image-card__image-container">
					<Img fluid={{ ...horizontalImageCardImage.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="horizontal-image-card__image" />
				</div>
			</div>
		</section>
	)
}
