import React from 'react'
import Img from 'gatsby-image'

export default function ArticleImage ( props ) {

	const { image, title, wideImage } = props.input

	return (
		<div className={`article-image ${ wideImage ? 'article-image--wide' : '' }`}>
			<div className="article-image__wrapper">
				<Img fluid={{ ...image.imageFile.childImageSharp.fluid }} className="article-image__image" />
				{ title && <span className="article-image__title">{ title }</span> }
			</div>
		</div>
	)
}
