import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function ServicesStandardTextBlock ( props ) {

	const { standardTextBlockText } = props.input

	return (
		<div className="services-standard-text-block wrapper">
			<ReactMarkdown
				className="services-standard-text-block__body"
				escapeHtml={ false }
				source={ standardTextBlockText }
				/>
		</div>
	)
}
