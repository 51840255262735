import React from 'react'
import { Link } from 'gatsby'

import { addTrailingSlash } from '../../../helpers/urlHelpers'

export default function CTABanner ( props ) {

	const { title, description, link, linkLabel, isExternalLink, link2, linkLabel2, isExternalLink2, link3, linkLabel3, isExternalLink3 } = props.input

	const renderLink = ( isExternalLink, label, url ) => {

		if ( isExternalLink ) {
			return <a href={ url } className="cta-banner__link" target="_blank" rel="noreferrer">{ label }</a>
		} else {
			return <Link to={ addTrailingSlash( url ) } className="cta-banner__link">{ label }</Link>
		}
	}

	return (
		<section className="cta-banner">
			<div className="cta-banner__wrapper wrapper">
				<h2 className="cta-banner__title">{ title }</h2>
				{ description &&
					<p className="cta-banner__description">{ description }</p>
				}
				<div className="cta-banner__link-container">
					{ link && linkLabel &&
						<>
							{ renderLink( isExternalLink, linkLabel, link ) }
						</>
					}
					{ link2 && linkLabel2 &&
						<>
							{ renderLink( isExternalLink2, linkLabel2, link2 ) }
						</>
					}
					{ link3 && linkLabel3 &&
						<>
							{ renderLink( isExternalLink3, linkLabel3, link3 ) }
						</>
					}
				</div>
			</div>
		</section>
	)
}
